<template>
  <div class="service">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="用户Uid">
        <el-input v-model="form.userId" placeholder="请输入用户Uid"></el-input>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
          is-range
          format="yyyy-MM-dd"
          clearable
          v-model="form.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="pickerChange"
          style="width: 280px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="doFilter">查询</el-button>
        <el-button type="primary" @click="noserch">重置</el-button>
        <el-button type="success" @click="addWater()">新增</el-button>
        <el-button type="success" style="margin: 0 15px" @click="exportData()">生成表格</el-button>
        <download-excel v-if="derivedForm" class="export" :data="derivedForm" :fields="jsonFields" type="xls" name="流水管理表.xls">
          下载表格
        </download-excel>
      </el-form-item>
    </el-form>
    <div class="table">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="userId" label="用户Uid"> </el-table-column>
        <el-table-column label="交易类型" width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.dealType == 1">收入</span>
            <span v-else>卖出</span>
          </template>
        </el-table-column>
        <el-table-column prop="typeDesc" label="类型描述"> </el-table-column>
        <el-table-column prop="amount" label="交易金额"> </el-table-column>
        <el-table-column prop="tokenNum" label="通证名称"> </el-table-column>

        <el-table-column prop="beforeAmount" label="出账前资金"> </el-table-column>
        <el-table-column prop="afterAmount" label="出账后资金"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
      </el-table>
    </div>
    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 新增 -->
    <el-dialog title="新增" :visible.sync="centerDialogVisible" width="30%" center :before-close="closeWater">
      <span>
        <el-form label-position="left" label-width="80px" :model="formLabelAlign">
          <el-form-item label="用户Uid">
            <el-input maxlength="6" type="number" v-model="formLabelAlign.userId"></el-input>
          </el-form-item>
          <el-form-item label="金额">
            <el-input type="number" v-model="formLabelAlign.amount"></el-input>
          </el-form-item>
          <el-form-item label="通证id">
            <el-select v-model="formLabelAlign.certificateId" placeholder="请选择通证">
              <el-option v-for="item in options" :key="item.certificateId" :label="item.tokenName" :value="item.certificateId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="formLabelAlign.type" placeholder="请选择">
              <el-option v-for="item in optionsTwo" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="二级密码">
            <el-input type="text" v-model="formLabelAlign.pwd"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeWater()">取 消</el-button>
        <el-button type="primary" @click="addNewWater()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        userId: '',
        time: '',
        startTime: '',
        endTime: '',
        currentPage: 1,
        pageSize: 20
      },
      derivedForm: '',
      formLabelAlign: {
        amount: '',
        certificateId: 0,
        type: '',
        pwd: '',
        userId: ''
      },
      options: [{ certificateId: 0, tokenName: 'CNY', tokenNum: '111111' }],
      optionsTwo: [
        {
          value: '1',
          label: '增加'
        },
        {
          value: '2',
          label: '减少'
        }
      ],
      jsonFields: {
        用户UID: 'userId',
        交易类型: 'dealType',
        类型描述: 'typeDesc',
        交易金额: 'amount',
        通证名称: 'tokenNum',
        出账前资金: 'beforeAmount',
        出账后资金: 'afterAmount',
        创建时间: 'createTime'
      },
      centerDialogVisible: false,
      tableData: [],
      total: 0
    }
  },
  mounted() {
    this.getData()
    this.getSeletor()
  },
  methods: {
    getData() {
      console.log(this.form)
      this.axios.get('/admin/userWater/showWater', this.form).then(res => {
        console.log(res, '111111111111')
        this.tableData = res.data.data.records
        this.total = res.data.data.total
      })
    },
    addWater() {
      this.centerDialogVisible = true
    },
    // 获取数字通证下拉框
    getSeletor() {
      this.axios.get('/admin/certificate/getDropDownBox', {}).then(res => {
        console.log(res, '222222222222')
        this.options.push(...res.data.data)
        // this.options.push({ certificateId: 0, tokenName: 'CNY', tokenNum: '111111' })
      })
    },
    // 时间
    pickerChange(val) {
      this.form.startTime = val[0]
      this.form.endTime = val[1]
    },
    // 添加流水
    addNewWater() {
      console.log(this.formLabelAlign)
      const that = this
      if (this.formLabelAlign.userId == '') {
        this.$message.error('请输入用户Uid')
      } else if (this.formLabelAlign.amount == '') {
        this.$message.error('请输入金额')
      } else if (this.formLabelAlign.amount <= 0) {
        this.$message.error('请输入大于零且不等于0的数字')
      } else if (this.formLabelAlign.type == '') {
        this.$message.error('请选择类型')
      } else if (this.formLabelAlign.pwd == '') {
        this.$message.error('请输入二级密码')
      } else {
        this.$confirm('确定添加', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            that.axios.post('/admin/userWater/updateBalance', that.formLabelAlign).then(res => {
              console.log(res)
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              that.centerDialogVisible = false
              that.formLabelAlign.userId = ''
              that.formLabelAlign.amount = ''
              that.formLabelAlign.certificateId = 0
              that.formLabelAlign.type = ''
              that.getData()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
      }
    },
    // 获取所有需要导出的数据
    exportData() {
      this.axios.get('/admin/userWater/getAll', this.form).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          window.open(`http://47.109.88.30:9071/${res.data.data}`)
        } else {
          console('2222')
        }
      })
      // this.axios.get('/admin/userWater/getTime', {}).then(res => {
      //   if (res.data.code == -1) {
      //     this.axios.get('/admin/userWater/getAll', this.form).then(res => {
      //       console.log(res)
      //       if (res.data.code == 200) {
      //         window.open(`http://47.109.88.30:9071/${res.data.data}`)
      //       } else {
      //         console('2222')
      //       }
      //     })
      //     // 注释这里的原因是后端说这个接口太耗资源，该用列表接口
      //     // this.axios.get('/admin/userWithdraw/withdrawExcel', {}).then(res => {
      //     // this.form.pageSize = 200
      //     // this.axios.get('/admin/userWater/showWater', this.form).then(res => {
      //     //   console.log(res)
      //     //   // this.derivedForm = res.data.data
      //     //   this.form.pageSize = 10
      //     //   this.derivedForm = res.data.data.records
      //     //   this.derivedForm.forEach(item => {
      //     //     item.dealType = item.dealType == 1 ? '收入' : '卖出'
      //     //   })
      //     // })
      //   } else {
      //     this.$message.error('本时段无法生成表格')
      //   }
      // })
    },
    // 取消新增
    closeWater() {
      this.formLabelAlign.userId = ''
      this.formLabelAlign.amount = ''
      this.formLabelAlign.certificateId = 0
      this.formLabelAlign.type = ''
      this.centerDialogVisible = false
    },
    doFilter() {
      this.getData()
    },
    noserch() {
      this.form.userId = ''
      this.form.time = ''
      this.form.endTime = ''
      this.form.startTime = ''
      this.form.currentPage = 1
      this.form.pageSize = 20
      this.getData()
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.form.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.theQuery {
  height: 70px;
  display: flex;
  margin-left: 30px;
  .theQuery-Tow {
    width: 200px;
    height: 70px;
    border: 1px solid red;
    margin-right: 45px;
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
// 导出样式
.export {
  color: #fff;
  background-color: #409eff;
  width: 90px;
  min-width: 90px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  display: inline-block;
}
.export:hover {
  cursor: pointer;
}
.operation {
  display: flex;
  div {
    margin-left: 12px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>